:root {
	--testimonials-background-color-1: unset;
	--testimonials-background-color-2: transparent;
	--testimonials-border-color-1: #585858;
}

#testimonials_module_contentbox {
	display: flex;
	align-items: center;
	text-align: center;
	margin: 0 auto !important;

	.cycle-sentinel {
		order: 2;
	}

	.right {
		transform: rotate(-45deg);
	}

	.left {
		transform: rotate(135deg);
	}

	.arrow {
		border: solid #969696;
		border-width: 0 3px 3px 0;
		font-size: 1.5rem;
		padding: 6px;
		background-color: var( --testimonials-background-color-1 );
		float: left;

		&:hover {
			cursor: pointer;
			border-color: var( --testimonials-border-color-1 );
		}
	}

	.right-arrow {
		z-index: 200;
		background-color: var( --testimonials-background-color-2 );
		border: none;
		cursor: pointer;
		padding: 4px;
		float: right;
		order: 3;
		align-items: center;
	}

	.left-arrow {
		z-index: 200;
		background-color: var( --testimonials-background-color-2 );
		border: none;
		cursor: pointer;
		padding: 4px;
		float: left;
		order: 1;
		align-items: center;
	}

	.testimonials-content {
		text-align: center;
		margin: 0 auto !important;
		width: 80%;
	}

	.testimonial-slider {
		width: 100%;
	}
}

#testimonials_module_contentbox {
	&.contentbox_item.withThumbnail {
		width: 100%;

		.testimonials-item {
			width: 100%;

			.image-box {
				img {
					max-width: 100%;
				}
			}
		}
	}

	&.contentbox_item {
		justify-content: center;
	}
}
